import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { Form, Breadcrumb } from 'react-bootstrap';
import { useAppContext } from 'components/AppContext';
import { useToastContext, Toast } from 'components/Toast';
import * as api from 'utils/api';
import Sidebar from 'components/Common/Sidebar';
import ItemsTable from 'components/Common/ItemsTable';
import { ModalKey } from 'constants/modal';
import { useModalContext } from 'components/ModalContext';

export default function CategoryDetails() {
  const history = useHistory();
  const { categoryId: categoryIdStr } = useParams();
  const { toastError } = useToastContext();
  const { request } = useAppContext();
  const { showModal } = useModalContext();
  const [category, setCategory] = useState(null);
  const [searchText, setSearchText] = useQueryParam('search', StringParam);
  const [page = 1, setPage] = useQueryParam('page', NumberParam);
  const [itemId = null, setItemId] = useQueryParam('itemId', NumberParam);
  const fetchItemsRef = useRef();

  const categoryId = parseInt(categoryIdStr);

  useEffect(() => {
    (async () => {
      const { success, result, error } = await request(api.getCategory(categoryId));

      if (success) {
        setCategory(result);
      } else {
        toastError(error);
      }
    })();
  }, [categoryId, request, toastError]);

  useEffect(() => {
    if (itemId) {
      showModal(ModalKey.EDIT_ITEM, {
        itemId,
        onClose: () => {
          setItemId(null);
          fetchItemsRef.current();
        },
      });
    } else {
      showModal(null);
    }
  }, [itemId, showModal, setItemId]);

  if (!category) {
    return null;
  }

  return (
    <div className="main categories">
      <Sidebar />
      <div className="right-part">
        <Toast />
        <div className="title">
          <Breadcrumb>
            <Breadcrumb.Item
              active={false}
              onClick={() => {
                history.push('/categories');
              }}
            >
              Categories
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {category.name}
            </Breadcrumb.Item>
          </Breadcrumb>

          <img src={category.thumbnail} alt="Thumbnail" />
        </div>

        <div>

          <div className="filters">
            <Form.Group>
              <Form.Label>Search by name</Form.Label>
              <Form.Control
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
              />
            </Form.Group>
          </div>
          <ItemsTable
            categoryId={categoryId}
            searchText={searchText}
            page={page}
            setPage={setPage}
            hideRooms
            hideCategories
            deleteButtonTitle="Remove"
            onDeleteItem={async (item, fetchItems) => {
              await request(api.editItem(item.id, {
                categoryIds: item.categories.map(c => c.id).filter(id => id !== categoryId),
              }));
              fetchItems();
            }}
            onAddItem={(fetchItems) => {
              showModal(ModalKey.ADD_ITEMS_TO_CATEGORY, {
                categoryId,
                onClose: fetchItems,
                onSubmit: ids => request(api.addItemsToCategory(categoryId, ids)),
              });
            }}
            onViewItem={item => {
              window.open(`${window.origin}/items?itemId=${item.id}`);
            }}
          />
        </div>
      </div>
    </div>
  );
}
