import React from 'react';
import { Pagination } from 'react-bootstrap';

export default function CustomPagination({
  page,
  numPages,
  setPage,
}) {
  const pages = [
    <Pagination.Prev
      key="prev"
      onClick={() => setPage(page - 1)}
      disabled={page === 1}
    />,
  ];

  if (numPages <= 10) {
    for (let i = 1; i <= numPages; i++) {
      pages.push(
        <Pagination.Item
          key={i}
          active={i === page}
          onClick={() => setPage(i)}
        >
          {i}
        </Pagination.Item>,
      );
    }
  } else {
    for (let i = 1; i <= 3; i++) {
      pages.push(
        <Pagination.Item
          key={i}
          active={i === page}
          onClick={() => setPage(i)}
        >
          {i}
        </Pagination.Item>,
      );
    }

    if (page === 4) {
      pages.push(
        <Pagination.Item
          key={page}
          active
          onClick={() => setPage(page)}
        >
          {page}
        </Pagination.Item>,
      );

      pages.push(
        <Pagination.Ellipsis
          key="ellipsis-up"
          onClick={() => setPage(Math.floor(page + (numPages - 2 - page) / 2))}
        />,
      );
    }

    if (page === numPages - 3) {
      pages.push(
        <Pagination.Ellipsis
          key="ellipsis-down"
          onClick={() => setPage(Math.floor(3 + (page - 3) / 2))}
        />,
      );

      pages.push(
        <Pagination.Item
          key={page}
          active
          onClick={() => setPage(page)}
        >
          {page}
        </Pagination.Item>,
      );
    }

    if (page > 4 && page < numPages - 3) {
      pages.push(
        <Pagination.Ellipsis
          key="ellipsis-down"
          onClick={() => setPage(Math.floor(3 + (page - 3) / 2))}
        />,
      );

      pages.push(
        <Pagination.Item
          key={page}
          active
          onClick={() => setPage(page)}
        >
          {page}
        </Pagination.Item>,
      );

      pages.push(
        <Pagination.Ellipsis
          key="ellipsis-up"
          onClick={() => setPage(Math.floor(page + (numPages - 2 - page) / 2))}
        />,
      );
    }

    if (page <= 3 || page >= numPages - 2) {
      pages.push(
        <Pagination.Ellipsis
          key="ellipsis-middle"
          onClick={() => setPage(Math.floor(numPages / 2))}
        />,
      );
    }

    for (let i = numPages - 2; i <= numPages; i++) {
      pages.push(
        <Pagination.Item
          key={i}
          active={i === page}
          onClick={() => setPage(i)}
        >
          {i}
        </Pagination.Item>,
      );
    }
  }

  pages.push(
    <Pagination.Next
      key="next"
      onClick={() => setPage(page + 1)}
      disabled={page === numPages}
    />,
  );

  return (
    <Pagination>{pages}</Pagination>
  );
}
