import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useQueryParam, NumberParam } from 'use-query-params';
import { Table, Button } from 'react-bootstrap';
import { useToastContext, Toast } from 'components/Toast';
import * as api from 'utils/api';
import Sidebar from 'components/Common/Sidebar';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import { useAppContext } from 'components/AppContext';
import { getLocaleDateString } from 'utils/time';
import { useHistory } from 'react-router-dom';

export default function Categories() {
  const history = useHistory();
  const { request } = useAppContext();
  const { toastError, toastSuccess } = useToastContext();
  const { showModal } = useModalContext();
  const [categories, setCategories] = useState([]);
  const [categoryId = null, setCategoryId] = useQueryParam('categoryId', NumberParam);
  const [submitting, setSubmitting] = useState(false);

  const fetchCategories = useCallback(async () => {
    const { error, result, success } = await request(api.getCategories());

    if (success) {
      setCategories(result);
    } else {
      toastError(error);
    }
  }, [toastError, request]);

  const moveUp = useCallback(async (id) => {
    setSubmitting(true);

    const { error, success } = await request(api.moveCategoryUp(id));

    if (success) {
      await fetchCategories();
    } else {
      toastError(error);
    }

    setSubmitting(false);
  }, [fetchCategories, request, toastError]);

  const moveDown = useCallback(async (id) => {
    setSubmitting(true);

    const { error, success } = await request(api.moveCategoryDown(id));

    if (success) {
      await fetchCategories();
    } else {
      toastError(error);
    }

    setSubmitting(false);
  }, [fetchCategories, request, toastError]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    if (categoryId) {
      showModal(ModalKey.EDIT_CATEGORY, {
        categoryId,
        onClose: () => {
          setCategoryId(null);
          fetchCategories();
        },
      });
    } else {
      showModal(null);
    }
  }, [categoryId, showModal, setCategoryId, fetchCategories]);

  return (
    <div className="main categories">
      <Sidebar />
      <div className="right-part">
        <Toast />
        <div className="title">
          Categories
        </div>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Thumbnail</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>
                <div className="actions">
                  <Button
                    onClick={() => showModal(ModalKey.EDIT_CATEGORY, {
                      onClose: fetchCategories,
                    })}
                  >
                    Add
                  </Button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {categories.map(category => (
              <tr key={category.id}>
                <td>
                  <div
                    className="category-name"
                    onClick={() => {
                      history.push(`/categories/${category.id}`);
                    }}
                  >
                    {category.name}
                  </div>
                </td>
                <td>
                  <img alt={category.name} src={category.thumbnail} />
                </td>
                <td>
                  {moment.utc(category.createdAt).local().format(`${getLocaleDateString()} HH:mm`)}
                </td>
                <td>
                  {moment.utc(category.updatedAt).local().format(`${getLocaleDateString()} HH:mm`)}
                </td>
                <td>
                  <div className="actions">
                    <Button
                      variant="light"
                      onClick={() => moveUp(category.id)}
                      disabled={submitting}
                    >
                      Up
                    </Button>
                    <Button
                      variant="light"
                      onClick={() => moveDown(category.id)}
                      disabled={submitting}
                    >
                      Down
                    </Button>
                    <Button
                      variant="light"
                      onClick={() => setCategoryId(category.id)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => showModal(ModalKey.DELETE_CONFIRMATION, {
                        text: 'Are you sure you want delete this category?',
                        onConfirm: async () => {
                          const { success, error } = await request(api.deleteCategory(category.id));
                          if (!success) {
                            toastError(error);
                          } else {
                            toastSuccess('Delete category successfully');
                            fetchCategories();
                          }
                        },
                      })}
                    >
                      Delete
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

    </div>
  );
}
