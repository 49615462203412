import React, { useMemo, useCallback, useState, useContext, useEffect } from 'react';
import * as api from 'utils/api';
import { setAuth, isAuth, logout as clearAuth } from 'utils/auth';
import { TenantType } from '../constants/common';

const AppContext = React.createContext();

export function AppContextProvider({
  children,
}) {
  const [loadingUser, setLoadingUser] = useState(true);
  const [user, setUser] = useState(null);
  const loading = useMemo(() => loadingUser, [loadingUser]);

  const logout = useCallback(() => {
    clearAuth();
    setUser(null);
  }, []);

  const request = useCallback(async (promise) => {
    try {
      const result = await promise;
      return {
        success: true,
        result,
      };
    } catch (error) {
      console.error(error);
      if (error.code === 401) {
        logout();
      }
      return {
        success: false,
        error,
      };
    }
  }, [logout]);

  const getInfo = useCallback(async () => {
    const {
      success,
      result: user,
    } = await request(api.getInfo());
    if (success) {
      setUser(user);
    }
  }, [request]);

  const login = useCallback(async (email, password) => {
    const {
      success,
      error,
      result,
    } = await request(api.login(email, password));

    if (success) {
      setAuth({
        accessToken: result.accessToken,
      });

      await getInfo();
    }

    return {
      success,
      error,
    };
  }, [getInfo, request]);

  const isRootTenant = useMemo(() => user && user.tenant && user.tenant.type === TenantType.ROOT, [user]);
  const isAgencyTenant = useMemo(() => user && user.tenant && user.tenant.type === TenantType.AGENCY, [user]);

  useEffect(() => {
    if (isAuth()) {
      (async () => {
        await getInfo();
        setLoadingUser(false);
      })();
    } else {
      setLoadingUser(false);
    }
  }, [getInfo]);

  const contextValue = useMemo(() => ({
    user,
    setUser,
    loading,
    login,
    logout,
    getInfo,
    request,
    isRootTenant,
    isAgencyTenant,
  }), [
    user,
    setUser,
    loading,
    login,
    logout,
    getInfo,
    request,
    isRootTenant,
    isAgencyTenant,
  ]);

  return (
    <AppContext.Provider
      value={contextValue}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
