import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useQueryParam, NumberParam } from 'use-query-params';
import { Table, Button } from 'react-bootstrap';
import { useToastContext, Toast } from 'components/Toast';
import * as api from 'utils/api';
import Sidebar from 'components/Common/Sidebar';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import { useAppContext } from 'components/AppContext';
import { getLocaleDateString } from 'utils/time';

export default function Designers() {
  const { request } = useAppContext();
  const { toastError } = useToastContext();
  const { showModal } = useModalContext();
  const [designers, setDesigners] = useState([]);
  const [designerId = null, setDesignerId] = useQueryParam('designerId', NumberParam);

  const fetchDesigners = useCallback(async () => {
    const { error, result, success } = await request(api.getDesigners());

    if (success) {
      setDesigners(result);
    } else {
      toastError(error);
    }
  }, [toastError, request]);

  useEffect(() => {
    fetchDesigners();
  }, [fetchDesigners]);

  useEffect(() => {
    if (designerId) {
      showModal(ModalKey.EDIT_DESIGNER, {
        designerId,
        onClose: () => setDesignerId(null),
      });
    } else {
      showModal(null);
    }
  }, [designerId, showModal, setDesignerId]);

  return (
    <div className="main designers">
      <Sidebar />
      <div className="right-part">
        <Toast />
        <div className="title">
          Designers
        </div>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Email</th>
              <th>Name</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>
                <div className="actions">
                  <Button
                    onClick={() => showModal(ModalKey.EDIT_DESIGNER, {
                      onClose: fetchDesigners,
                    })}
                  >
                    Add
                  </Button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {designers.map(designer => (
              <tr key={designer.id}>
                <td>{designer.id}</td>
                <td>{designer.email}</td>
                <td>{designer.name}</td>
                <td>
                  {moment.utc(designer.createdAt).local().format(`${getLocaleDateString()} HH:mm`)}
                </td>
                <td>
                  {moment.utc(designer.updatedAt).local().format(`${getLocaleDateString()} HH:mm`)}
                </td>
                <td>
                  <div className="actions">
                    <Button
                      variant="light"
                      onClick={() => setDesignerId(designer.id)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => showModal(ModalKey.DELETE_CONFIRMATION, {
                        onConfirm: async () => {
                          const { success, error } = await request(api.deleteDesigner(designer.id));
                          if (!success) {
                            toastError(error);
                          } else {
                            fetchDesigners();
                          }
                        },
                      })}
                    >
                      Delete
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

    </div>
  );
}
