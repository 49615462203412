import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDebounce } from 'use-debounce';
import { useQueryParam, NumberParam, StringParam, DateParam } from 'use-query-params';
import { Form, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Sidebar from 'components/Common/Sidebar';
import { useToastContext, Toast } from 'components/Toast';
import { useModalContext } from 'components/ModalContext';
import { useAppContext } from 'components/AppContext';
import { getLocaleDateString } from 'utils/time';
import * as api from 'utils/api';
import CustomPagination from 'components/Common/CustomPagination';
import { ModalKey } from 'constants/modal';

const ITEMS_PER_PAGE = 10;

const today = moment().endOf('day').toDate();
const a30daysAgo = moment().startOf('day').subtract(30, 'd').toDate();

export default function Transactions() {
  const { showModal } = useModalContext();
  const { request } = useAppContext();
  const { toastError } = useToastContext();
  const [transactions, setTransactions] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [start = a30daysAgo, setStart] = useQueryParam('start', DateParam);
  const [end = today, setEnd] = useQueryParam('end', DateParam);
  const [type = 'all', setType] = useQueryParam('type', StringParam);
  const [page = 1, setPage] = useQueryParam('page', NumberParam);
  const [userEmail = '', setUserEmail] = useQueryParam('userEmail');
  const [transactionId = null, setTransactionId] = useQueryParam('transactionId', NumberParam);
  const [numPages, setNumPages] = useState(0);

  const [debouncedUserEmail] = useDebounce(userEmail, 500, {
    leading: true,
    trailing: true,
  });

  const startTS = start.getTime();
  const endTS = end.getTime();

  useEffect(() => {
    (async () => {
      const { success, error, result } = await request(api.getTransactions({
        start: startTS,
        end: endTS,
        type: type !== 'all' ? type : undefined,
        limit: ITEMS_PER_PAGE,
        offset: (page - 1) * ITEMS_PER_PAGE,
        userEmail: debouncedUserEmail.trim(),
      }));

      if (success) {
        setTransactions(result.transactions);
        setTotalAmount(result.totalAmount);
        setNumPages(Math.ceil(result.count / ITEMS_PER_PAGE));
      } else {
        toastError(error);
      }
    })();
  }, [startTS, endTS, type, page, request, toastError, debouncedUserEmail]);

  useEffect(() => {
    if (transactionId) {
      showModal(ModalKey.TRANSACTION_DETAILS, {
        transactionId,
        onClose: () => setTransactionId(null),
      });
    } else {
      showModal(null);
    }
  }, [transactionId, showModal, setTransactionId]);

  return (
    <div className="main transactions">
      <Sidebar />
      <div className="right-part">
        <Toast />
        <div className="title">
          Transactions
        </div>
        <div className="filters">
          <Form.Group>
            <Form.Label>Type</Form.Label>
            <Form.Select
              value={type}
              onChange={e => setType(e.target.value)}
            >
              <option value="all">All</option>
              <option value="top_up">Top up</option>
              <option value="consume_to_upload_image">Consume to upload image</option>
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Start Date</Form.Label>
            <DatePicker
              selected={start}
              onChange={(date) => setStart(date)}
              dateFormat={getLocaleDateString().replaceAll('D', 'd')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>End Date</Form.Label>
            <DatePicker
              selected={end}
              onChange={(date) => setEnd(date)}
              dateFormat={getLocaleDateString().replaceAll('D', 'd')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>User Email</Form.Label>
            <Form.Control
              value={userEmail || ''}
              type="text"
              onChange={e => setUserEmail(e.target.value)}
            />
          </Form.Group>
        </div>
        <div
          style={{
            marginTop: 8,
            marginBottom: 8,
          }}
        >
          <span>
            Total Amount:
          </span>
          {' '}
          <span
            style={{
              fontWeight: 'bold',
            }}
          >
            $
            {totalAmount / 100}
          </span>
        </div>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>User Email</th>
              <th>Type</th>
              <th>Status</th>
              <th>Amount</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>{' '}</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map(transaction => (
              <tr key={transaction.id}>
                <td>
                  <div
                    className="transaction-id"
                    onClick={() => setTransactionId(transaction.id)}
                  >
                    {transaction.id}
                  </div>
                </td>
                <td>
                  <div
                    className="transaction-id"
                    onClick={() => {
                      window.open(`${window.origin}/users?search=${transaction.user.email}`);
                    }}
                  >
                    {transaction.user.email}
                  </div>
                </td>
                <td>{transaction.type}</td>
                <td>{transaction.status}</td>
                <td>
                  {transaction.amount !== null && `$${transaction.amount / 100}`}
                </td>
                <td>
                  {transaction.price !== null && `$${transaction.price / 100}`}
                </td>
                <td>{transaction.quantity}</td>
                <td>
                  {moment.utc(transaction.createdAt).local().format(`${getLocaleDateString()} HH:mm`)}
                </td>
                <td>
                  {moment.utc(transaction.updatedAt).local().format(`${getLocaleDateString()} HH:mm`)}
                </td>
                <td>{' '}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="pagination-wrapper">
          <CustomPagination
            page={page}
            setPage={setPage}
            numPages={numPages}
          />
        </div>
      </div>
    </div>
  );
}
