import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useAppContext } from 'components/AppContext';
import { Toast, useToastContext } from 'components/Toast';
import * as api from 'utils/api';

export default function EditUser({
  onClose,
  userId,
}) {
  const { toastError } = useToastContext();
  const { request, getInfo } = useAppContext();
  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (userId) {
      (async () => {
        const { success, result, error } = await request(api.getUser(userId));

        if (success) {
          setData(result);
        } else {
          toastError(error);
        }
      })();
    } else {
      setData({
        password: '',
        email: '',
        name: '',
        balance: 0,
        active: true,
        freemium: true,
      });
    }
  }, [userId, request, toastError]);

  if (!data) {
    return null;
  }

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      id="modal-edit-user"
      size="small"
    >
      <Toast
        onModal
      />
      <Modal.Header closeButton>
        <Modal.Title>
          {userId ? 'Edit' : 'Add'}
          {' '}
          User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="form-group">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            value={data.email}
            onChange={(e) => setData({
              ...data,
              email: e.target.value,
            })}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>Name</Form.Label>
          <Form.Control
            value={data.name}
            onChange={(e) => setData({
              ...data,
              name: e.target.value,
            })}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>Active</Form.Label>
          <Form.Check
            type="radio"
            label="Active"
            checked={data.active}
            onChange={() => {}}
            onClick={() => setData({
              ...data,
              active: true,
            })}
          />
          <Form.Check
            type="radio"
            label="Inactive"
            checked={!data.active}
            onChange={() => {}}
            onClick={() => setData({
              ...data,
              active: false,
            })}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Check
            type="checkbox"
            checked={data.freemium}
            label="Freemium"
            onChange={() => {}}
            onClick={() => setData({
              ...data,
              freemium: !data.freemium,
            })}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>Coins</Form.Label>
          <Form.Control
            type="number"
            value={data.balance}
            step={0.5}
            onChange={(e) => setData({
              ...data,
              balance: parseFloat(e.target.value),
            })}
          />
        </Form.Group>
        {data.password !== undefined ? (
          <Form.Group className="form-group">
            <Form.Label>Password</Form.Label>
            <Form.Control
              value={data.password}
              onChange={(e) => setData({
                ...data,
                password: e.target.value,
              })}
            />
          </Form.Group>
        ) : (
          <Button
            variant="light"
            onClick={() => setData({
              ...data,
              password: '',
            })}
          >
            Change Password
          </Button>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={submitting}
          onClick={async () => {
            setSubmitting(true);

            if (!data.name) {
              toastError('Name is required');
              setSubmitting(false);
              return;
            }

            if (!data.email) {
              toastError('Email is required');
              setSubmitting(false);
              return;
            }

            if (data.password === '') {
              toastError('Password is required');
              setSubmitting(false);
              return;
            }

            let res;

            const postData = {
              ...data,
              name: data.name.trim(),
              email: data.email.trim(),
            };

            delete postData.id;
            delete postData.avatar;
            delete postData.usedCoins;
            delete postData.createdAt;
            delete postData.updatedAt;
            delete postData.ip;
            delete postData.settings;
            delete postData.readTos;

            if (userId) {
              res = await request(api.editUser(data.id, postData));
            } else {
              res = await request(api.addUser(postData));
            }

            const { success, error } = res;

            if (!success) {
              toastError(error);
              setSubmitting(false);
            } else {
              getInfo();
              onClose();
            }
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
