import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import pluralize from 'pluralize';
import { useHistory } from 'react-router-dom';
import { useQueryParam, NumberParam } from 'use-query-params';
import { Table, Button, Breadcrumb, Form } from 'react-bootstrap';
import { useToastContext, Toast } from 'components/Toast';
import * as api from 'utils/api';
import Sidebar from 'components/Common/Sidebar';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import { useAppContext } from 'components/AppContext';
import { getLocaleDateString } from 'utils/time';

export default function Rooms() {
  const history = useHistory();
  const { request } = useAppContext();
  const { toastError, toastSuccess } = useToastContext();
  const { showModal } = useModalContext();
  const [rooms, setRooms] = useState([]);
  const [roomId = null, setRoomId] = useQueryParam('roomId', NumberParam);
  const [parentRoomId = null, setParentRoomId] = useQueryParam('parentRoomId', NumberParam);
  const [parentRoom, setParentRoom] = useState(null);
  const [searchText = '', setSearchText] = useQueryParam('search');
  const [submitting, setSubmitting] = useState(false);

  const fetchRooms = useCallback(async () => {
    if (!parentRoomId) {
      const { error, result, success } = await request(api.getRooms());

      if (success) {
        setRooms(result);
      } else {
        toastError(error);
      }
    } else {
      const { error, result, success } = await request(api.getRoom(parentRoomId));

      if (success) {
        setParentRoom(result);
        setRooms(result.children);
      } else {
        toastError(error);
      }
    }
  }, [toastError, request, parentRoomId]);

  const moveUp = useCallback(async (id) => {
    setSubmitting(true);

    const { error, success } = await request(api.moveRoomUp(id));

    if (success) {
      await fetchRooms();
    } else {
      toastError(error);
    }

    setSubmitting(false);
  }, [fetchRooms, request, toastError]);

  const moveDown = useCallback(async (id) => {
    setSubmitting(true);

    const { error, success } = await request(api.moveRoomDown(id));

    if (success) {
      await fetchRooms();
    } else {
      toastError(error);
    }

    setSubmitting(false);
  }, [fetchRooms, request, toastError]);

  useEffect(() => {
    fetchRooms();
  }, [fetchRooms]);

  useEffect(() => {
    setSearchText('');
  }, [parentRoomId, setSearchText]);

  useEffect(() => {
    if (roomId) {
      showModal(ModalKey.EDIT_ROOM, {
        roomId,
        parentId: parentRoomId,
        onClose: () => {
          setRoomId(null);
          fetchRooms();
        },
      });
    } else {
      showModal(null);
    }
  }, [roomId, showModal, setRoomId, fetchRooms, parentRoomId]);

  useEffect(() => {
    if (!parentRoomId) {
      setParentRoom(null);
    }
  }, [parentRoomId]);

  return (
    <div className="main rooms">
      <Sidebar />
      <div className="right-part">
        <Toast />
        <div className="title">
          <Breadcrumb>
            <Breadcrumb.Item
              active={!parentRoomId}
              onClick={() => {
                setParentRoomId(null);
              }}
            >
              Rooms
            </Breadcrumb.Item>
            <Breadcrumb.Item active={!!parentRoomId}>
              {parentRoom?.name}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="filters">
          <Form.Group>
            <Form.Label>Search by name</Form.Label>
            <Form.Control
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
          </Form.Group>
        </div>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Thumbnail</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>
                <div className="actions">
                  <Button
                    onClick={() => showModal(ModalKey.EDIT_ROOM, {
                      onClose: fetchRooms,
                      parentId: parentRoomId,
                    })}
                  >
                    Add
                  </Button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {rooms
              .filter(room => room.name.toLowerCase().includes(searchText.toLowerCase()))
              .map(room => (
                <tr key={room.id}>
                  <td>
                    {!parentRoomId ? (
                      <>
                        <div
                          className="view-categories-button"
                          onClick={() => {
                            setParentRoomId(room.id);
                          }}
                        >
                          {room.name}
                        </div>
                        {room.children && (
                          <div>
                            {room.children.length}
                            {' '}
                            {pluralize('style', room.children.length)}
                          </div>
                        )}
                      </>
                    ) : (
                      <div
                        className="view-categories-button"
                        onClick={() => {
                          history.push(`/rooms/${room.id}`);
                        }}
                      >
                        {room.name}
                      </div>
                    )}

                  </td>
                  <td>
                    <img alt={room.name} src={room.thumbnail} />
                  </td>
                  <td>
                    {moment.utc(room.createdAt).local().format(`${getLocaleDateString()} HH:mm`)}
                  </td>
                  <td>
                    {moment.utc(room.updatedAt).local().format(`${getLocaleDateString()} HH:mm`)}
                  </td>
                  <td>
                    <div className="actions">
                      <Button
                        variant="light"
                        onClick={() => moveUp(room.id)}
                        disabled={submitting}
                      >
                        Up
                      </Button>
                      <Button
                        variant="light"
                        onClick={() => moveDown(room.id)}
                        disabled={submitting}
                      >
                        Down
                      </Button>
                      <Button
                        variant="light"
                        onClick={() => setRoomId(room.id)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => showModal(ModalKey.DELETE_CONFIRMATION, {
                          text: `Are you sure you want delete this ${parentRoomId ? 'style' : 'room'}?`,
                          onConfirm: async () => {
                            const { success, error } = await request(api.deleteRoom(room.id));
                            if (!success) {
                              toastError(error);
                            } else {
                              toastSuccess(`Delete ${parentRoomId ? 'style' : 'room'} successfully`);
                              fetchRooms();
                            }
                          },
                        })}
                      >
                        Delete
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>

    </div>
  );
}
