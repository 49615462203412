import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useDebounce } from 'use-debounce';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { Table, Button, Form } from 'react-bootstrap';
import { useToastContext, Toast } from 'components/Toast';
import * as api from 'utils/api';
import Sidebar from 'components/Common/Sidebar';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import { useAppContext } from 'components/AppContext';
import CustomPagination from 'components/Common/CustomPagination';
import { getLocaleDateString } from 'utils/time';

const ITEMS_PER_PAGE = 10;

export default function Items() {
  const { request } = useAppContext();
  const { toastError, toastSuccess } = useToastContext();
  const { showModal } = useModalContext();
  const [items, setItems] = useState([]);
  const [page = 1, setPage] = useQueryParam('page', NumberParam);
  const [numPages, setNumPages] = useState(0);
  const [searchText, setSearchText] = useQueryParam('search', StringParam);
  const [itemId = null, setItemId] = useQueryParam('itemId', NumberParam);
  const [userId = null, setUserId] = useQueryParam('userId', NumberParam);

  const [debouncedSearchText] = useDebounce(searchText, 500, {
    leading: true,
    trailing: true,
  });

  const fetchItems = useCallback(async () => {
    const { error, result, success } = await request(api.getItems({
      limit: ITEMS_PER_PAGE,
      offset: (page - 1) * ITEMS_PER_PAGE,
      search: debouncedSearchText,
      userId: userId || undefined,
    }));

    if (success) {
      setItems(result.items);
      setNumPages(Math.ceil(result.count / ITEMS_PER_PAGE));
    } else {
      toastError(error);
    }
  }, [toastError, page, request, debouncedSearchText, userId]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems, page]);

  useEffect(() => {
    if (itemId) {
      showModal(ModalKey.EDIT_ITEM, {
        itemId,
        onClose: () => {
          setItemId(null);
          fetchItems();
        },
      });
    } else {
      showModal(null);
    }
  }, [itemId, showModal, setItemId, fetchItems]);

  return (
    <div className="main items">
      <Sidebar />
      <div className="right-part">
        <Toast />
        <div className="title">
          Items
        </div>
        <div className="filters">
          <Form.Group>
            <Form.Label>Search by name</Form.Label>
            <Form.Control
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>User ID</Form.Label>
            <Form.Control
              type="number"
              step={1}
              value={userId}
              onChange={e => setUserId(e.target.value ? parseInt(e.target.value) : null)}
            />
          </Form.Group>
        </div>

        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Thumbnail</th>
              <th>Room Styles</th>
              <th>Categories</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>
                <div className="actions">
                  <Button
                    onClick={() => showModal(ModalKey.EDIT_ITEM, {
                      onClose: fetchItems,
                      userId,
                    })}
                  >
                    Add
                  </Button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {items.map(item => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>
                  <img alt={item.name} src={item.thumbnail} />
                </td>
                <td>
                  {item.rooms.map(r => r.name).join(', ')}
                </td>
                <td>
                  {item.categories.map(c => c.name).join(', ')}
                </td>
                <td>
                  {moment.utc(item.createdAt).local().format(`${getLocaleDateString()} HH:mm`)}
                </td>
                <td>
                  {moment.utc(item.updatedAt).local().format(`${getLocaleDateString()} HH:mm`)}
                </td>
                <td>
                  <div className="actions">
                    <Button
                      variant="light"
                      onClick={() => setItemId(item.id)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => showModal(ModalKey.DELETE_CONFIRMATION, {
                        onConfirm: async () => {
                          const { success, error } = await request(api.deleteItem(item.id));
                          if (!success) {
                            toastError(error);
                          } else {
                            toastSuccess('Delete item successfully');
                            fetchItems();
                          }
                        },
                      })}
                    >
                      Delete
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="pagination-wrapper">
          <CustomPagination
            page={page}
            setPage={setPage}
            numPages={numPages}
          />
        </div>
      </div>

    </div>
  );
}
