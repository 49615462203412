import React, { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { Table, Button, Form, Modal, Badge, CloseButton } from 'react-bootstrap';
import { useAppContext } from 'components/AppContext';
import { useToastContext, Toast } from 'components/Toast';
import * as api from 'utils/api';
import CustomPagination from 'components/Common/CustomPagination';

const ITEMS_PER_PAGE = 10;

export default function AddItemsToCategory({
  onClose,
  categoryId,
  roomId,
  onSubmit,
}) {
  const { request } = useAppContext();
  const { toastError } = useToastContext();
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [addedItems, setAddedItems] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const [debouncedSearchText] = useDebounce(searchText, 500, {
    leading: true,
    trailing: true,
  });

  const fetchItems = useCallback(async () => {
    const { error, result, success } = await request(api.getItems({
      limit: ITEMS_PER_PAGE,
      offset: (page - 1) * ITEMS_PER_PAGE,
      search: debouncedSearchText,
    }));

    if (success) {
      setItems(result.items);
      setNumPages(Math.ceil(result.count / ITEMS_PER_PAGE));
    } else {
      toastError(error);
    }
  }, [toastError, page, request, debouncedSearchText]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems, page]);

  console.log({
    items,
  });

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      id="modal-add-items-to-category"
      size="md"
    >
      <Toast
        onModal
      />
      <Modal.Header closeButton>
        <Modal.Title>Add items</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="added-items">
          {addedItems.map(item => (
            <Badge key={item.id}>
              {item.name}
              <CloseButton
                variant="white"
                onClick={() => {
                  setAddedItems(addedItems.filter(i => i.id !== item.id));
                }}
              />
            </Badge>
          ))}
        </div>
        <div className="filters">
          <Form.Group>
            <Form.Label>Search by name</Form.Label>
            <Form.Control
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
          </Form.Group>
        </div>
        <Table>
          <thead>
            <tr>
              <th>Checked</th>
              <th>ID</th>
              <th>Name</th>
              <th>Thumbnail</th>
            </tr>
          </thead>
          <tbody>
            {items.map(item => (
              <tr key={item.id}>
                <td>
                  {(!item.categories.find(c => c.id === categoryId) && !item.rooms.find(r => r.id === roomId)) && (
                  <Form.Check
                    type="checkbox"
                    checked={!!addedItems.find(i => i.id === item.id)}
                    onChange={() => {}}
                    onClick={() => {
                      if (addedItems.find(i => i.id === item.id)) {
                        setAddedItems(addedItems.filter(i => i.id !== item.id));
                      } else {
                        setAddedItems([...addedItems, item]);
                      }
                    }}
                  />
                  )}

                </td>
                <td>{item.id}</td>
                <td>
                  <div
                    onClick={() => {
                      window.open(`${window.origin}/items?itemId=${item.id}`);
                    }}
                    className="item-name"
                  >
                    {item.name}
                  </div>
                </td>
                <td>
                  <img alt={item.name} src={item.thumbnail} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="pagination-wrapper">
          <CustomPagination
            page={page}
            setPage={setPage}
            numPages={numPages}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={submitting}
          onClick={async () => {
            setSubmitting(true);
            const { success, error } = await onSubmit(addedItems.map(item => item.id));

            if (!success) {
              toastError(error);
              setSubmitting(false);
            } else {
              onClose();
            }
          }}
        >
          Add items
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
