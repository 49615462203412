import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import langCodes from 'assets/lang.json';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';

export default function TranslationFields({
  value,
  onChange,
}) {
  const [data, setData] = useState(value || []);

  useEffect(() => {
    onChange(data.filter(d => !!d.language));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  console.log(data);

  return (
    <div style={{
      display: 'flex',
      gap: 16,
      flexDirection: 'column',
    }}
    >
      {data.map(t => (
        <div
          key={t.id}
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            gap: 16,
          }}
        >
          <Select
            styles={{
              input: style => ({
                ...style,
                width: 100,
              }),
            }}
            options={langCodes.map(l => ({
              value: l.code,
              label: l.name,
            }))}
            onChange={value => {
              setData(data.map(d => {
                if (d.id === t.id) {
                  return {
                    ...d,
                    language: value.value,
                  };
                }

                return d;
              }));
            }}
            defaultValue={langCodes.find(l => l.code === t.language) ? {
              id: t.language,
              label: langCodes.find(l => l.code === t.language).name,
            } : undefined}
          />
          <Form.Control
            value={t.text}
            onChange={e => {
              setData(data.map(d => {
                if (d.id === t.id) {
                  return {
                    ...d,
                    text: e.target.value,
                  };
                }

                return d;
              }));
            }}
          />
          <Button
            variant="link"
            style={{
              color: 'red',
              width: 100,
              minWidth: 0,
              minHeight: 0,
            }}
            onClick={() => {
              setData(data.filter(d => d.id !== t.id));
            }}
          >
            Remove
          </Button>
        </div>
      ))}
      <div style={{
        display: 'flex',
        justifyContent: 'center',
      }}
      >
        <Button
          variant="link"
          onClick={() => {
            setData([...data, {
              language: null,
              text: '',
              id: uuidv4(),
            }]);
          }}
        >
          Add translation
        </Button>
      </div>
    </div>
  );
}
