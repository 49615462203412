import * as Sentry from '@sentry/browser';
import QueryString from 'query-string';
import config from 'configuration';
import { getToken } from 'utils/auth';

const addBreadCrumb = (method, endpoint, params, headers, response) => {
  Sentry.addBreadcrumb({
    data: {
      endpoint,
      method,
      response: JSON.stringify(response),
      params: JSON.stringify(params),
      headers: JSON.stringify(headers),
    },
    category: 'Network',
    level: 'info',
  });
};

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

function ServerAPIError(json, code) {
  this.name = 'ServerAPIError';
  this.data = json;
  this.code = code;
  this.stack = (new Error()).stack;
}
ServerAPIError.prototype = Object.create(Error.prototype);
ServerAPIError.prototype.constructor = ServerAPIError;

const request = async (url, method, body, customHeaders = {}) => {
  let endpoint = url;
  if (!url.startsWith('http')) {
    endpoint = config.apiUrl + url;
  }
  const headers = {
    ...defaultHeaders,
    ...customHeaders,
  };

  const token = getToken();

  if (token) {
    // Set empty Bearer can cause setRequestHeader error in old safari version
    headers.Authorization = `Bearer ${token}`;
  }

  const fetchOpts = {
    method,
    headers,
  };

  if (method !== 'HEAD' && method !== 'GET') {
    if (headers['Content-Type'] === 'application/json') {
      fetchOpts.body = JSON.stringify(body);
    } else {
      delete headers['Content-Type'];
      fetchOpts.body = body;
    }
  }

  const start = Date.now();
  const response = await fetch(endpoint, fetchOpts);
  const end = Date.now();
  const responseTime = end - start;

  const json = await response.json();

  const logData = {
    method,
    url: endpoint,
    status: response.status,
    responseTime,
  };

  if (response.status < 200 || response.status >= 300) {
    if (json) {
      logData.error = json;
      addBreadCrumb(method, endpoint, body, headers, json);
      throw new ServerAPIError(json, response.status);
    } else {
      logData.error = response.statusText;
      addBreadCrumb(method, endpoint, body, headers, response.statusText);
      throw new Error(response.statusText);
    }
  }

  addBreadCrumb(method, endpoint, body, headers, json);
  return json;
};

export const get = (endpoint, params, headers = {}) => {
  let url = endpoint;
  if (params) {
    url += `?${QueryString.stringify(params)}`;
  }
  return request(url, 'GET', null, headers);
};

export const post = (endpoint, body, headers = {}) => (
  request(endpoint, 'POST', body, headers)
);

export const put = (endpoint, body, headers = {}) => (
  request(endpoint, 'PUT', body, headers)
);

export const del = (endpoint, body, headers = {}) => (
  request(endpoint, 'DELETE', body, headers)
);
