import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useDebounce } from 'use-debounce';
import { Table, Button } from 'react-bootstrap';
import { useToastContext } from 'components/Toast';
import * as api from 'utils/api';
import { useAppContext } from 'components/AppContext';
import CustomPagination from 'components/Common/CustomPagination';
import { getLocaleDateString } from 'utils/time';

const ITEMS_PER_PAGE = 10;

export default function ItemsTable({
  searchText,
  roomId,
  categoryId,
  page,
  setPage,
  onEditItem,
  onAddItem,
  onDeleteItem,
  deleteButtonTitle,
  onViewItem,
  hideCategories,
  hideRooms,
}) {
  const { request } = useAppContext();
  const { toastError } = useToastContext();
  const [items, setItems] = useState([]);
  const [numPages, setNumPages] = useState(0);

  const [debouncedSearchText] = useDebounce(searchText, 500, {
    leading: true,
    trailing: true,
  });

  const fetchItems = useCallback(async () => {
    const { error, result, success } = await request(api.getItems({
      limit: ITEMS_PER_PAGE,
      offset: (page - 1) * ITEMS_PER_PAGE,
      search: debouncedSearchText,
      roomId,
      categoryId,
    }));

    if (success) {
      setItems(result.items);
      setNumPages(Math.ceil(result.count / ITEMS_PER_PAGE));
    } else {
      toastError(error);
    }
  }, [toastError, page, request, debouncedSearchText, roomId, categoryId]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems, page]);

  return (
    <div className="items-table">
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Thumbnail</th>
            {!hideRooms && (
              <th>Rooms</th>
            )}
            {!hideCategories && (
              <th>Categories</th>
            )}
            <th>Created At</th>
            <th>Updated At</th>
            <th>
              <div className="actions">
                <Button
                  onClick={() => onAddItem(fetchItems)}
                >
                  Add
                </Button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <tr key={item.id}>
              <td>
                {onViewItem ? (
                  <div
                    onClick={() => onViewItem(item)}
                    className="item-name"
                  >
                    {item.name}
                  </div>
                ) : (
                  item.name
                )}

              </td>
              <td>
                <img alt={item.name} src={item.thumbnail} />
              </td>
              {!hideRooms && (
                <td>
                  {item.rooms.map(r => r.name).join(', ')}
                </td>
              )}
              {!hideCategories && (
                <td>
                  {item.categories.map(c => c.name).join(', ')}
                </td>
              )}
              <td>
                {moment.utc(item.createdAt).local().format(`${getLocaleDateString()} HH:mm`)}
              </td>
              <td>
                {moment.utc(item.updatedAt).local().format(`${getLocaleDateString()} HH:mm`)}
              </td>
              <td>
                <div className="actions">
                  {onEditItem && (
                    <Button
                      variant="light"
                      onClick={() => onEditItem(item, fetchItems)}
                    >
                      Edit
                    </Button>
                  )}

                  <Button
                    variant="danger"
                    onClick={() => onDeleteItem(item, fetchItems)}
                  >
                    {deleteButtonTitle}
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="pagination-wrapper">
        <CustomPagination
          page={page}
          setPage={setPage}
          numPages={numPages}
        />
      </div>
    </div>
  );
}
