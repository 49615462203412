export const STORAGE_PREFIX = 'undefined';

export const TenantType = {
  ROOT: 1,
  AGENCY: 2,
};

export const TenantStatus = {
  ACTIVATED: 1,
  CLOSED: 2,
};

export const ITEMS_PER_PAGE = 10;

export const ImageType = {
  NORMAL: 'normal',
  PANO: 'pano',
  FLOORPLAN: 'floorplan',
};

export const ImageTypeToNameMap = {
  [ImageType.NORMAL]: '2D',
  [ImageType.PANO]: '360°',
  [ImageType.FLOORPLAN]: 'Floor Plan',
};
