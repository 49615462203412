import React, { useMemo, useCallback, useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useModalContext } from './ModalContext';

const ToastContext = React.createContext();

const TIMEOUT = 5 * 1000;

export function ToastContextProvider({
  children,
}) {
  const [messages, setMessages] = useState([]);

  const toast = useCallback((message, type) => {
    const id = uuidv4();

    setMessages(messages => [{
      id,
      type,
      message,
    }, ...messages]);

    setTimeout(() => {
      setMessages(messages => messages.filter(message => message.id !== id));
    }, TIMEOUT);
  }, []);

  const toastError = useCallback((error) => {
    toast(error.data?.message || error.message || error || 'Something went wrong', 'error');
  }, [toast]);

  const toastSuccess = useCallback((message) => {
    toast(message, 'success');
  }, [toast]);

  const contextValue = useMemo(() => ({
    messages,
    toastError,
    toastSuccess,
  }), [
    messages,
    toastError,
    toastSuccess,
  ]);

  return (
    <ToastContext.Provider
      value={contextValue}
    >
      {children}
    </ToastContext.Provider>
  );
}

export function useToastContext() {
  return useContext(ToastContext);
}

export function Toast({
  onModal,
}) {
  const { messages } = useToastContext();
  const { modal } = useModalContext();

  if (modal && !onModal) {
    return null;
  }

  return (
    <div className="toast-container">
      {messages.map(message => (
        <div
          key={message.id}
          className={`toast-message ${message.type}`}
        >
          {message.message}
        </div>
      ))}
    </div>
  );
}
