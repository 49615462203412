import React, { useEffect } from 'react';
import moment from 'moment';
import ReactJson from 'react-json-view';
import { Modal, Table } from 'react-bootstrap';
import { getLocaleDateString } from 'utils/time';
import { useAppContext } from 'components/AppContext';
import * as api from 'utils/api';
import { useToastContext } from 'components/Toast';

export default function TransactionDetails({
  onClose,
  transactionId,
}) {
  const { request } = useAppContext();
  const { toastError } = useToastContext();
  const [transaction, setTransaction] = React.useState(null);

  useEffect(() => {
    (async () => {
      const { success, result, error } = await request(api.getTransaction(transactionId));

      if (success) {
        setTransaction(result);
      } else {
        toastError(error);
      }
    })();
  }, [transactionId, request, toastError]);

  if (!transaction) {
    return null;
  }

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      id="modal-transaction-details"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Transaction Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table>
          <tbody>
            <tr>
              <td>
                ID
              </td>
              <td>
                {transaction.id}
              </td>
            </tr>
            <tr>
              <td>
                Type
              </td>
              <td>
                {transaction.type}
              </td>
            </tr>
            <tr>
              <td>
                User ID
              </td>
              <td>
                {transaction.userId}
              </td>
            </tr>
            <tr>
              <td>
                Amount
              </td>
              <td>
                {transaction.amount !== null && `$${transaction.amount / 100}`}
              </td>
            </tr>
            <tr>
              <td>
                Price
              </td>
              <td>
                {transaction.price !== null && `$${transaction.price / 100}`}
              </td>
            </tr>
            <tr>
              <td>
                Quantity
              </td>
              <td>
                {transaction.quantity}
              </td>
            </tr>
            <tr>
              <td>
                Created At
              </td>
              <td>
                {moment.utc(transaction.createdAt).local().format(`${getLocaleDateString()} HH:mm`)}
              </td>
            </tr>
            <tr>
              <td>
                Updated At
              </td>
              <td>
                {moment.utc(transaction.updatedAt).local().format(`${getLocaleDateString()} HH:mm`)}
              </td>
            </tr>
            <tr>
              <td>
                Paypal Order ID
              </td>
              <td>
                {transaction.paypalOrderId}
              </td>
            </tr>
            <tr>
              <td>
                Paypal Capture ID
              </td>
              <td>
                {transaction.paypalCaptureId}
              </td>
            </tr>
            <tr>
              <td>
                Other data
              </td>
              <td>
                <div className="json-wrapper">
                  <ReactJson
                    src={transaction.info}
                    name="info"
                    displayDataTypes={false}
                    enableClipboard={false}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}
