import React, { useState, useEffect, useCallback } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useToastContext, Toast } from 'components/Toast';
import * as api from 'utils/api';
import Sidebar from 'components/Common/Sidebar';
import { useAppContext } from 'components/AppContext';

function FileField({
  config,
  onSubmit,
}) {
  const [url, setUrl] = useState(config.value);
  const { request } = useAppContext();
  const { toastError } = useToastContext();

  useEffect(() => {
    setUrl(config.value);
  }, [config.value]);

  return (
    <Form.Group className="form-group" key={config.id}>
      <Form.Label>
        {config.name}
      </Form.Label>

      {url ? (
        <div>
          <div
            style={{
              marginBottom: 8,
            }}
          >
            <a href={config.value} target="__blank">{config.value}</a>
          </div>
          <Button
            variant="light"
            onClick={() => setUrl('')}
          >
            Change
          </Button>
        </div>

      ) : (
        <Form.Control
          type="file"
          onChange={async e => {
            const { success, error, result } = await request(api.uploadFile({
              file: e.target.files[0],
              isPublic: config.public,
            }));

            if (!success) {
              toastError(error);
            } else {
              setUrl(result.url);
              onSubmit(config, result.url);
            }
          }}
        />
      )}

      <Form.Text muted>
        {config.description}
      </Form.Text>
    </Form.Group>
  );
}

function Field({
  config,
  onSubmit,
}) {
  const [value, setValue] = useState(config.value);

  useEffect(() => {
    setValue(config.value);
  }, [config.value]);

  return (
    <Form.Group className="form-group" key={config.id}>
      <Form.Label>
        {config.name}
      </Form.Label>
      <Form.Control
        type={(config.dataType === 'integer' || config.dataType === 'float') ? 'number' : 'text'}
        value={value}
        onChange={e => {
          if (config.dataType === 'integer') {
            setValue(parseInt(e.target.value));
          } else if (config.dataType === 'float') {
            setValue(parseFloat(e.target.value));
          } else {
            setValue(e.target.value);
          }
        }}
        onBlur={() => {
          onSubmit(config, value);
        }}
      />
      <Form.Text muted>
        {config.description}
      </Form.Text>
    </Form.Group>
  );
}

function BooleanField({
  config,
  onSubmit,
}) {
  return (
    <Form.Group className="form-group" key={config.id}>
      <Form.Label>
        {config.name}
      </Form.Label>
      <Form.Check
        type="radio"
        label="True"
        id={`${config.name}-true`}
        checked={config.value === 'true'}
        onClick={() => onSubmit(config, 'true')}
        onChange={() => {}}
      />
      <Form.Check
        type="radio"
        label="False"
        id={`${config.name}-false`}
        checked={config.value === 'false'}
        onClick={() => onSubmit(config, 'false')}
        onChange={() => {}}
      />
      <Form.Text muted>
        {config.description}
      </Form.Text>
    </Form.Group>
  );
}

export default function Config() {
  const { request } = useAppContext();
  const { toastError, toastSuccess } = useToastContext();
  const [configs, setConfigs] = useState([]);

  const fetchConfigs = useCallback(async() => {
    const { error, result, success } = await request(api.getConfigs());

    if (!success) {
      toastError(error);
    } else {
      setConfigs(result);
    }
  }, [request, toastError]);

  const onSubmit = useCallback(async(config, value) => {
    const { success, error } = await request(api.updateConfig(config.id, value));

    if (success) {
      toastSuccess('Update config successfully');
      await fetchConfigs();
    } else {
      toastError(error);
    }
  }, [fetchConfigs, request, toastError, toastSuccess]);

  useEffect(() => {
    fetchConfigs();
  }, [fetchConfigs]);

  return (
    <div className="main configs">
      <Sidebar />
      <div className="right-part">
        <Toast />
        <div className="title">
          Configs
        </div>
        <div>
          {configs.map(config => (
            <React.Fragment key={config.id}>
              {config.dataType === 'file' && (
                <FileField
                  config={config}
                  key={config.id}
                  onSubmit={onSubmit}
                />
              )}
              {config.dataType === 'boolean' && (
                <BooleanField
                  config={config}
                  key={config.id}
                  onSubmit={onSubmit}
                />
              )}
              {['integer', 'text', 'float'].includes(config.dataType) && (
                <Field
                  config={config}
                  key={config.id}
                  onSubmit={onSubmit}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>

    </div>
  );
}
