import React from 'react';
import moment from 'moment';
import { Table, Button } from 'react-bootstrap';
import CustomPagination from 'components/Common/CustomPagination';
import { getLocaleDateString } from 'utils/time';
import { useAppContext } from '../AppContext';

export default function UsersTable({
  users,
  setUserId,
  page,
  setPage,
  numPages,
  renderActions,
}) {
  const { isRootTenant } = useAppContext();
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Email</th>
            <th>Name</th>
            <th>Status</th>
            <th>IP</th>
            <th>Coins</th>
            <th>Used Coins</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>
              {renderActions?.()}
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.email}</td>
              <td>{user.name}</td>
              <td>{user.active ? 'Active' : 'Inactive'}</td>
              <td>{user.ip}</td>
              <td>{user.balance}</td>
              <td>{user.usedCoins}</td>
              <td>
                {moment.utc(user.createdAt).local().format(`${getLocaleDateString()} HH:mm`)}
              </td>
              <td>
                {moment.utc(user.updatedAt).local().format(`${getLocaleDateString()} HH:mm`)}
              </td>
              <td>
                <div className="actions">
                  {isRootTenant && (
                  <Button
                    variant="light"
                    onClick={() => {
                      window.open(`${window.origin}/items?userId=${user.id}`);
                    }}
                  >
                    Customized Items
                  </Button>
                  )}
                  <Button
                    variant="light"
                    onClick={() => {
                      window.open(`${window.origin}/images?userEmail=${user.email}`);
                    }}
                  >
                    Images
                  </Button>
                  <Button
                    variant="light"
                    onClick={() => {
                      window.open(`${window.origin}/transactions?userEmail=${user.email}`);
                    }}
                  >
                    Transactions
                  </Button>
                  <Button
                    variant="light"
                    onClick={() => setUserId(user.id)}
                  >
                    Edit
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="pagination-wrapper">
        <CustomPagination
          page={page}
          setPage={setPage}
          numPages={numPages}
        />
      </div>
    </>
  );
}
