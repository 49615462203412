import { useAppContext } from 'components/AppContext';
import { useToastContext, Toast } from 'components/Toast';
import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

export default function Login() {
  const { login: doLogin } = useAppContext();
  const { toastError } = useToastContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const login = async () => {
    setSubmitting(true);
    const { success, error } = await doLogin({
      email,
      password,
    });
    if (!success) {
      toastError(error);
      setSubmitting(false);
    }
  };

  return (
    <div className="login">
      <Toast />
      <div className="title">Login</div>
      <Form.Group className="form-group">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="form-group">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Group>
      <Button
        onClick={login}
        disabled={submitting}
      >
        Login
      </Button>
    </div>
  );
}
