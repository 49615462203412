import React from 'react';
import { Modal, Table } from 'react-bootstrap';

export default function ImageFurnitureItems({
  onClose,
  objects,
}) {
  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      id="modal-image-furniture-items"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Image Furniture Items</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Thumbnail</th>
            </tr>
          </thead>
          <tbody>
            {objects.map((o) => (
              <tr key={o.id}>
                <td>
                  <div
                    onClick={() => {
                      window.open(`${window.origin}/items?itemId=${o.item.id}`);
                    }}
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: 'var(--bs-primary)',
                    }}
                  >
                    {o.item.name}
                  </div>
                </td>
                <td>
                  <img src={o.item.thumbnail} alt={o.item.name} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}
