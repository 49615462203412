import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { useAppContext } from 'components/AppContext';
import { useToastContext, Toast } from 'components/Toast';
import { Modal, Button, Form } from 'react-bootstrap';
import * as api from 'utils/api';
import { getToken } from 'utils/auth';
import { validateFileExtensions } from 'utils/validation';
import TranslationFields from 'components/Common/TranslationFields';

export default function EditItem({
  onClose,
  itemId,
  categoryId,
  roomId,
  userId,
}) {
  const { toastError } = useToastContext();
  const { request } = useAppContext();
  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [categories, setCategories] = useState([]);

  const [loadingRooms, setLoadingRooms] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(true);

  const loading = loadingRooms || loadingCategories;

  useEffect(() => {
    (async () => {
      const { error, result, success } = await request(api.getNestedRooms());

      if (success) {
        setRooms(result);
      } else {
        toastError(error);
      }

      setLoadingRooms(false);
    })();
  }, [request, toastError]);

  useEffect(() => {
    (async () => {
      const { error, result, success } = await request(api.getCategories());

      if (success) {
        setCategories(result);
      } else {
        toastError(error);
      }

      setLoadingCategories(false);
    })();
  }, [request, toastError]);

  useEffect(() => {
    if (itemId) {
      (async () => {
        const { success, result, error } = await request(api.getItem(itemId));

        if (success) {
          const { id, name, active, image, model, fakeModel, rooms, categories, metadata, translation } = result;

          setData({
            id, name, active, image, model, fakeModel, rooms, categories, metadata, translation,
          });
        } else {
          toastError(error);
        }
      })();
    } else {
      setData({
        name: '',
        active: true,
        image: null,
        model: null,
        fakeModel: null,
        rooms: [rooms.find(r => r.id === roomId)].filter(r => !!r),
        categories: [categories.find(r => r.id === categoryId)].filter(c => !!c),
        metadata: {
          stickToWalls: false,
          stickToGround: true,
          stickToCeil: false,
          putOnTable: false,
        },
        translation: {
          name: [],
        },
      });
    }
  }, [itemId, request, rooms, categories, roomId, categoryId, toastError]);

  const imageUrl = useMemo(() => {
    if (data?.image instanceof File) {
      return URL.createObjectURL(data.image);
    }
    return data?.image;
  }, [data?.image]);

  if (loading) {
    return null;
  }

  if (!data) {
    return null;
  }

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      id="modal-edit-item"
      size="small"
    >
      <Toast
        onModal
      />
      <Modal.Header closeButton>
        <Modal.Title>
          {itemId ? 'Edit' : 'Add'}
          {' '}
          Item
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="form-group">
          <Form.Label>Name</Form.Label>
          <Form.Control
            value={data.name}
            onChange={(e) => setData({
              ...data,
              name: e.target.value,
            })}
          />
          <TranslationFields
            value={data.translation.name}
            onChange={value => {
              setData({
                ...data,
                translation: {
                  ...data.translation,
                  name: value,
                },
              });
            }}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>Position</Form.Label>
          <Form.Check
            type="radio"
            label="Stick to ground"
            checked={data.metadata.stickToGround}
            onChange={() => {}}
            onClick={() => setData({
              ...data,
              metadata: {
                ...data.metadata,
                stickToGround: true,
                stickToWalls: false,
                stickToCeil: false,
                putOnTable: false,
              },
            })}
          />
          <Form.Check
            type="radio"
            label="Stick to walls"
            checked={data.metadata.stickToWalls}
            onChange={() => {}}
            onClick={() => setData({
              ...data,
              metadata: {
                ...data.metadata,
                stickToGround: false,
                stickToWalls: true,
                stickToCeil: false,
                putOnTable: false,
              },
            })}
          />
          <Form.Check
            type="radio"
            label="Stick to ceil"
            checked={data.metadata.stickToCeil}
            onChange={() => {}}
            onClick={() => setData({
              ...data,
              metadata: {
                ...data.metadata,
                stickToGround: false,
                stickToWalls: false,
                stickToCeil: true,
                putOnTable: false,
              },
            })}
          />

          <Form.Check
            type="radio"
            label="Put on table"
            checked={data.metadata.putOnTable}
            onChange={() => {}}
            onClick={() => setData({
              ...data,
              metadata: {
                ...data.metadata,
                stickToGround: false,
                stickToWalls: false,
                stickToCeil: false,
                putOnTable: true,
              },
            })}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>Room Styles</Form.Label>
          <Select
            options={rooms.map(r => ({
              value: r.id,
              label: r.name,
            }))}
            defaultValue={data.rooms.map(r => ({
              value: r.id,
              label: r.name,
            }))}
            isMulti
            onChange={options => {
              setData({
                ...data,
                rooms: options.map(o => rooms.find(r => r.id === o.value)),
              });
            }}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>Categories</Form.Label>
          <Select
            options={categories.map(c => ({
              value: c.id,
              label: c.name,
            }))}
            defaultValue={data.categories.map(c => ({
              value: c.id,
              label: c.name,
            }))}
            isMulti
            onChange={options => {
              setData({
                ...data,
                categories: options.map(o => categories.find(c => c.id === o.value)),
              });
            }}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>Image</Form.Label>
          {data.image ? (
            <>
              <Button
                variant="light"
                onClick={() => setData({
                  ...data,
                  image: null,
                })}
              >
                Change
              </Button>
              <img
                alt="Preview"
                className="image"
                src={imageUrl}
              />
            </>
          ) : (
            <Form.Control
              type="file"
              accept="image/jpeg,image/png"
              onChange={(e) => {
                if (validateFileExtensions(e.target.files[0], ['jpeg', 'png', 'jpg'])) {
                  setData({
                    ...data,
                    image: e.target.files[0],
                  });
                } else {
                  toastError('Invalid file type');
                  e.target.value = '';
                }
              }}
            />
          )}
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>
            Model
          </Form.Label>
          {data.model ? (
            <>
              <div>
                {data.modelFileId ? `File ID: ${data.modelFileId}` : data.model.name}
              </div>
              <Button
                variant="light"
                onClick={() => setData({
                  ...data,
                  model: null,
                  modelFileId: null,
                })}
              >
                Change
              </Button>

              {typeof data.model === 'string' && (
                <Button
                  variant="light"
                  onClick={() => {
                    window.open(`${data.model}?accessToken=${getToken()}`);
                  }}
                >
                  Download
                </Button>
              )}
            </>
          ) : (
            <Form.Control
              type="file"
              accept=".blend"
              onChange={(e) => {
                if (validateFileExtensions(e.target.files[0], ['blend'])) {
                  setData({
                    ...data,
                    model: e.target.files[0],
                  });
                } else {
                  toastError('Invalid file type');
                  e.target.value = '';
                }
              }}
            />
          )}
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>
            Fake model
          </Form.Label>
          {data.fakeModel ? (
            <>
              <div>
                {data.fakeModelFileId ? `File ID: ${data.fakeModelFileId}` : data.fakeModel.name}
              </div>
              <Button
                variant="light"
                onClick={() => setData({
                  ...data,
                  fakeModel: null,
                  fakeModelFileId: null,
                })}
              >
                Change
              </Button>

              {typeof data.model === 'string' && (
                <Button
                  variant="light"
                  onClick={() => {
                    window.open(data.fakeModel);
                  }}
                >
                  Download
                </Button>
              )}
            </>
          ) : (
            <Form.Control
              type="file"
              accept=".glb"
              onChange={(e) => {
                if (validateFileExtensions(e.target.files[0], ['glb'])) {
                  setData({
                    ...data,
                    fakeModel: e.target.files[0],
                  });
                } else {
                  toastError('Invalid file type');
                  e.target.value = '';
                }
              }}
            />
          )}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={submitting}
          onClick={async () => {
            setSubmitting(true);

            if (!data.name) {
              toastError('Name is required');
              setSubmitting(false);
              return;
            }

            if (!data.image) {
              toastError('Image is required');
              setSubmitting(false);
              return;
            }

            if (!data.model) {
              toastError('Model is required');
              setSubmitting(false);
              return;
            }

            if (!data.fakeModel) {
              toastError('Fake Model is required');
              setSubmitting(false);
              return;
            }

            let res;
            const postData = {
              ...data,
              name: data.name.trim(),
            };

            if (userId) {
              postData.userId = userId;
            }

            postData.roomIds = postData.rooms.map(r => r.id);
            postData.categoryIds = postData.categories.map(c => c.id);

            delete postData.rooms;
            delete postData.categories;
            delete postData.id;
            delete postData.modelFileId;
            delete postData.fakeModelFileId;

            if (typeof postData.image === 'string') {
              delete postData.image;
            }

            if (typeof postData.model === 'string') {
              delete postData.model;
            }

            if (typeof postData.fakeModel === 'string') {
              delete postData.fakeModel;
            }

            if (itemId) {
              res = await request(api.editItem(data.id, postData));
            } else {
              res = await request(api.addItem(postData));
            }

            const { success, error } = res;

            if (!success) {
              toastError(error);
              setSubmitting(false);
            } else {
              onClose();
            }
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
