import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

export default function EditTenantStatusConfirmation({
  onClose,
  onConfirm,
  text,
  statusText,
}) {
  const [submitting, setSubmitting] = useState(false);

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      id="modal-edit-status-tenant-confirmation"
      size="small"
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {text || `Are you sure you want ${statusText} this tenant?`}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={submitting}
          variant="danger"
          onClick={async () => {
            setSubmitting(true);
            await onConfirm();
            setSubmitting(false);
            onClose();
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
