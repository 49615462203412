import React, { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useToastContext, Toast } from 'components/Toast';
import * as api from 'utils/api';
import Sidebar from 'components/Common/Sidebar';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import { useAppContext } from 'components/AppContext';
import { getToken } from 'utils/auth';
import UsersTable from './UsersTable';

const ITEMS_PER_PAGE = 10;

export default function Users() {
  const history = useHistory();
  const { request } = useAppContext();
  const { toastError } = useToastContext();
  const { showModal } = useModalContext();
  const [users, setUsers] = useState([]);
  const [page = 1, setPage] = useQueryParam('page', NumberParam);
  const [numPages, setNumPages] = useState(0);
  const [searchText, setSearchText] = useQueryParam('search', StringParam);
  const [userId = null, setUserId] = useQueryParam('userId', NumberParam);
  const [exportId, setExportId] = useState(null);
  const [exportFileUrl, setExportFileUrl] = useState(null);

  const [debouncedSearchText] = useDebounce(searchText, 500, {
    leading: true,
    trailing: true,
  });

  const fetchUsers = useCallback(async () => {
    const { error, result, success } = await request(api.getUsers({
      limit: ITEMS_PER_PAGE,
      offset: (page - 1) * ITEMS_PER_PAGE,
      search: debouncedSearchText,
    }));

    if (success) {
      setUsers(result.users);
      setNumPages(Math.ceil(result.count / ITEMS_PER_PAGE));
    } else {
      toastError(error);
    }
  }, [toastError, page, request, debouncedSearchText]);

  const exportUsers = useCallback(async () => {
    setExportFileUrl(null);

    const { error, result, success } = await request(api.exportUsers({
      search: debouncedSearchText,
    }));

    if (success) {
      setExportId(result.id);
    } else {
      toastError(error);
    }
  }, [debouncedSearchText, request, toastError]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers, page]);

  useEffect(() => {
    if (userId) {
      showModal(ModalKey.EDIT_USER, {
        userId,
        onClose: () => {
          setUserId(null);
          fetchUsers();
        },
      });
    } else {
      showModal(null);
    }
  }, [userId, showModal, setUserId, fetchUsers]);

  useEffect(() => {
    if (exportId) {
      const interval = setInterval(async () => {
        const { result, success } = await request(api.getExport(exportId));

        if (success) {
          if (result.status === 'done') {
            setExportId(null);
            setExportFileUrl(result.file);
          }
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }

    return () => { };
  }, [exportId, request]);

  return (
    <div className="main users">
      <Sidebar />
      <div className="right-part">
        <Toast />
        <div className="title">
          Users
        </div>
        <div className="filters">
          <Form.Group>
            <Form.Label>Search by email or name</Form.Label>
            <Form.Control
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
          </Form.Group>
        </div>
        <UsersTable
          users={users}
          setUserId={setUserId}
          page={page}
          setPage={setPage}
          numPages={numPages}
          renderActions={() => (
            <div
              className="actions"
            >
              {exportFileUrl && (
                <a href={`${exportFileUrl}?accessToken=${getToken()}`}>Download</a>
              )}
              <Button
                disabled={!!exportId}
                onClick={() => {
                  exportUsers();
                }}
              >
                Export
              </Button>
              <Button
                onClick={() => {
                  history.push('/users/suspicious-users');
                }}
              >
                View suspicious users
              </Button>
              <Button
                onClick={() => showModal(ModalKey.EDIT_USER, {
                  onClose: fetchUsers,
                })}
              >
                Add
              </Button>
            </div>
          )}
        />
      </div>

    </div>
  );
}
