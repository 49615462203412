import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { Form, Breadcrumb } from 'react-bootstrap';
import { useAppContext } from 'components/AppContext';
import { useToastContext, Toast } from 'components/Toast';
import * as api from 'utils/api';
import Sidebar from 'components/Common/Sidebar';
import ItemsTable from 'components/Common/ItemsTable';
import { ModalKey } from 'constants/modal';
import { useModalContext } from 'components/ModalContext';

export default function RoomDetails() {
  const history = useHistory();
  const { roomId: roomIdStr } = useParams();
  const { toastError, toastSuccess } = useToastContext();
  const { request } = useAppContext();
  const { showModal } = useModalContext();
  const [room, setRoom] = useState(null);
  const [searchText, setSearchText] = useQueryParam('search', StringParam);
  const [page = 1, setPage] = useQueryParam('page', NumberParam);
  const [itemId = null, setItemId] = useQueryParam('itemId', NumberParam);
  const fetchItemsRef = useRef();

  const roomId = parseInt(roomIdStr);

  useEffect(() => {
    (async () => {
      const { success, result, error } = await request(api.getRoom(roomId));

      if (success) {
        setRoom(result);
      } else {
        toastError(error);
      }
    })();
  }, [roomId, request, toastError]);

  useEffect(() => {
    if (itemId) {
      showModal(ModalKey.EDIT_ITEM, {
        itemId,
        onClose: () => {
          setItemId(null);
          fetchItemsRef.current();
        },
      });
    } else {
      showModal(null);
    }
  }, [itemId, showModal, setItemId]);

  if (!room) {
    return null;
  }

  return (
    <div className="main rooms">
      <Sidebar />
      <div className="right-part">
        <Toast />
        <div className="title">
          <Breadcrumb>
            <Breadcrumb.Item
              active={false}
              onClick={() => {
                history.push('/rooms');
              }}
            >
              Rooms
            </Breadcrumb.Item>
            <Breadcrumb.Item
              active={false}
              onClick={() => {
                history.push(`/rooms?parentRoomId=${room.parent?.id}`);
              }}
            >
              {room.parent?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {room.name}
            </Breadcrumb.Item>
          </Breadcrumb>

          <img
            alt="Thumbnail"
            src={room.thumbnail}
          />
        </div>

        <div>

          <div className="filters">
            <Form.Group>
              <Form.Label>Search by name</Form.Label>
              <Form.Control
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
              />
            </Form.Group>
          </div>
          <ItemsTable
            roomId={roomId}
            searchText={searchText}
            page={page}
            setPage={setPage}
            hideRooms
            hideCategories
            deleteButtonTitle="Remove"
            onDeleteItem={async (item, fetchItems) => {
              showModal(ModalKey.DELETE_CONFIRMATION, {
                text: 'Are you sure you want to remove this item from current style?',
                onConfirm: async () => {
                  await request(api.editItem(item.id, {
                    roomIds: item.rooms.map(c => c.id).filter(id => id !== roomId),
                  }));
                  toastSuccess('Remove item successfully');
                  fetchItems();
                },
              });
            }}
            onAddItem={(fetchItems) => {
              showModal(ModalKey.ADD_ITEMS_TO_CATEGORY, {
                roomId,
                onClose: fetchItems,
                onSubmit: ids => request(api.addItemsToRoom(roomId, ids)),
              });
            }}
            onViewItem={item => {
              window.open(`${window.origin}/items?itemId=${item.id}`);
            }}
          />
        </div>
      </div>
    </div>
  );
}
