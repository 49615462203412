import React from 'react';
import ReactLoading from 'react-loading';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useAppContext } from 'components/AppContext';
import Login from 'components/Login';
import Admins from 'components/Admins';
import Designers from 'components/Designers';
import Users from './Users';
import SuspiciousUsers from './Users/SuspiciousUsers';
import Transactions from './Transactions';
import Rooms from './Rooms';
import Categories from './Categories';
import CategoryDetails from './Categories/CategoryDetails';
import Items from './Items';
import Images from './Images';
import RoomDetails from './Rooms/RoomDetails';
import Configs from './Configs';
import ImageDetails from './Images/ImageDetails';
import RenderProgresses from './RenderProgresses';
import Tenants from './Tenants';

function App() {
  const { user, loading, isRootTenant } = useAppContext();

  if (loading) {
    return (
      <div className="full-screen-loading">
        <ReactLoading type="cylon" color="#ff9c34" />
      </div>
    );
  }

  if (!user) {
    return (
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Redirect to="/login" />
      </Switch>
    );
  }

  return (
    <Switch>
      {user.level === 0 && isRootTenant && (
        <Route path="/tenants">
          <Tenants />
        </Route>
      )}
      {user.level === 0 && (
        <Route path="/admins">
          <Admins />
        </Route>
      )}
      {isRootTenant && (
      <Route path="/designers">
        <Designers />
      </Route>
      )}
      <Route path="/users/suspicious-users">
        <SuspiciousUsers />
      </Route>
      <Route path="/users">
        <Users />
      </Route>
      <Route path="/images/:imageId">
        <ImageDetails />
      </Route>
      <Route path="/images">
        <Images />
      </Route>
      {isRootTenant && (
      <Route path="/render-progresses">
        <RenderProgresses />
      </Route>
      )}
      <Route path="/transactions">
        <Transactions />
      </Route>
      {isRootTenant && (
      <Route path="/rooms/:roomId">
        <RoomDetails />
      </Route>
      )}
      {isRootTenant && (
      <Route path="/rooms">
        <Rooms />
      </Route>
      )}
      {isRootTenant && (
      <Route path="/categories/:categoryId">
        <CategoryDetails />
      </Route>
      )}
      {isRootTenant && (
      <Route path="/categories">
        <Categories />
      </Route>
      )}
      {isRootTenant && (
      <Route path="/items">
        <Items />
      </Route>
      )}
      <Route path="/configs">
        <Configs />
      </Route>
      <Redirect to="/users" />
    </Switch>
  );
}

export default App;
