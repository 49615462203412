import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Col, Row } from 'react-bootstrap';
import { useAppContext } from 'components/AppContext';
import { Toast, useToastContext } from 'components/Toast';
import * as api from 'utils/api';

export default function EditTenantInfo({
  onClose,
  tenantId,
}) {
  const { toastError } = useToastContext();
  const { request } = useAppContext();
  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (tenantId) {
      (async () => {
        const { success, result, error } = await request(api.getTenant(tenantId));

        if (success) {
          setData(result);
        } else {
          toastError(error);
        }
      })();
    } else {
      setData({
        name: '',
        appUrl: '',
        paypalClient: '',
        paypalSecret: '',
        googleClientId: '',
        noreplyEmailAccount: '',
        noreplyEmailPassword: '',
        noreplyEmailHost: '',
        noreplyEmailPort: '',
        noreplyEmailSecure: true,
      });
    }
  }, [tenantId, request, toastError]);

  if (!data) {
    return null;
  }

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      id="modal-edit-tenant"
      size="small"
    >
      <Toast
        onModal
      />
      <Modal.Header closeButton>
        <Modal.Title>
          {tenantId ? 'Edit' : 'Add'}
          {' '}
          Tenant
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="form-group">
          <Form.Label>Name</Form.Label>
          <Form.Control
            value={data.name}
            onChange={(e) => setData({
              ...data,
              name: e.target.value,
            })}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>App Url</Form.Label>
          <Form.Control
            value={data.appUrl}
            onChange={(e) => setData({
              ...data,
              appUrl: e.target.value,
            })}
          />
        </Form.Group>
        <div className="group-tenant-info">
          <span>Paypal</span>
        </div>
        <Row>
          <Col>
            <Form.Group className="form-group">
              <Form.Label className="font-normal">Client</Form.Label>
              <Form.Control
                value={data.paypalClient}
                onChange={(e) => setData({
                  ...data,
                  paypalClient: e.target.value,
                })}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="form-group">
              <Form.Label className="font-normal">Secret</Form.Label>
              <Form.Control
                value={data.paypalSecret}
                onChange={(e) => setData({
                  ...data,
                  paypalSecret: e.target.value,
                })}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="group-tenant-info" />
        <Form.Group className="form-group">
          <Form.Label>Google Client Id</Form.Label>
          <Form.Control
            value={data.googleClientId}
            onChange={(e) => setData({
              ...data,
              googleClientId: e.target.value,
            })}
          />
        </Form.Group>
        <div className="group-tenant-info">
          <span>Email</span>
        </div>
        <Row>
          <Col>
            <Form.Group className="form-group">
              <Form.Label className="font-normal">Account</Form.Label>
              <Form.Control
                value={data.noreplyEmailAccount}
                onChange={(e) => setData({
                  ...data,
                  noreplyEmailAccount: e.target.value,
                })}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="form-group">
              <Form.Label className="font-normal">Password</Form.Label>
              <Form.Control
                value={data.noreplyEmailPassword}
                onChange={(e) => setData({
                  ...data,
                  noreplyEmailPassword: e.target.value,
                })}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="form-group">
              <Form.Label className="font-normal">Host</Form.Label>
              <Form.Control
                value={data.noreplyEmailHost}
                onChange={(e) => setData({
                  ...data,
                  noreplyEmailHost: e.target.value,
                })}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="form-group">
              <Form.Label className="font-normal">Port</Form.Label>
              <Form.Control
                type="number"
                value={data.noreplyEmailPort}
                onChange={(e) => setData({
                  ...data,
                  noreplyEmailPort: e.target.value,
                })}
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="form-group">
          <Form.Check
            type="checkbox"
            checked={data.noreplyEmailSecure}
            label="Email Secure"
            className="font-normal"
            onChange={() => {}}
            onClick={() => setData({
              ...data,
              noreplyEmailSecure: !data.noreplyEmailSecure,
            })}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={submitting}
          onClick={async () => {
            setSubmitting(true);
            const toastErrorInfo = [
              {
                key: 'name',
                message: 'Name is required',
              },
              {
                key: 'appUrl',
                message: 'App Url is required',
              },
              {
                key: 'paypalClient',
                message: 'Paypal Client is required',
              },
              {
                key: 'paypalSecret',
                message: 'Paypal Secret is required',
              },
              {
                key: 'googleClientId',
                message: 'Google Client Id is required',
              },
              {
                key: 'noreplyEmailAccount',
                message: 'Email Account is required',
              },
              {
                key: 'noreplyEmailPassword',
                message: 'Email Password is required',
              },
              {
                key: 'noreplyEmailHost',
                message: 'Email Host is required',
              },
              {
                key: 'noreplyEmailPort',
                message: 'Email Port is required',
              },
            ];

            for (const info of toastErrorInfo) {
              if (!data[info.key]) {
                toastError(info.message);
                setSubmitting(false);
                return;
              }
            }

            let res;

            const postData = {
              name: data.name.trim(),
              appUrl: data.appUrl.trim(),
              paypalClient: data.paypalClient.trim(),
              paypalSecret: data.paypalSecret.trim(),
              googleClientId: data.googleClientId.trim(),
              noreplyEmailAccount: data.noreplyEmailAccount.trim(),
              noreplyEmailPassword: data.noreplyEmailPassword.trim(),
              noreplyEmailHost: data.noreplyEmailHost.trim(),
              noreplyEmailPort: data.noreplyEmailPort.toString().trim(),
              noreplyEmailSecure: data.noreplyEmailSecure,
            };

            if (tenantId) {
              res = await request(api.editTenant(data.id, postData));
            } else {
              res = await request(api.addTenant(postData));
            }

            const { success, error } = res;

            if (!success) {
              toastError(error);
              setSubmitting(false);
            } else {
              onClose();
            }
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
