import React, { useState, useEffect, useMemo } from 'react';
import { useAppContext } from 'components/AppContext';
import { useToastContext, Toast } from 'components/Toast';
import { Modal, Button, Form } from 'react-bootstrap';
import * as api from 'utils/api';
import { validateFileExtensions } from 'utils/validation';
import TranslationFields from 'components/Common/TranslationFields';

export default function EditRoom({
  onClose,
  roomId,
  parentId,
}) {
  const { toastError } = useToastContext();
  const { request } = useAppContext();
  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (roomId) {
      (async () => {
        const { success, result, error } = await request(api.getRoom(roomId));

        if (success) {
          setData(result);
        } else {
          toastError(error);
        }
      })();
    } else {
      setData({
        name: '',
        active: true,
        thumbnail: null,
        translation: {
          name: [],
        },
      });
    }
  }, [roomId, request, toastError]);

  const thumbnailUrl = useMemo(() => {
    if (data?.thumbnail instanceof File) {
      return URL.createObjectURL(data.thumbnail);
    }
    return data?.thumbnail;
  }, [data?.thumbnail]);

  if (!data) {
    return null;
  }

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      id="modal-edit-room"
      size="small"
    >
      <Toast
        onModal
      />
      <Modal.Header closeButton>
        <Modal.Title>
          {roomId ? 'Edit' : 'Add'}
          {' '}
          {parentId ? 'Style' : 'Room'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="form-group">
          <Form.Label>Name</Form.Label>
          <Form.Control
            value={data.name}
            onChange={(e) => setData({
              ...data,
              name: e.target.value,
            })}
          />
          <TranslationFields
            value={data.translation.name}
            onChange={value => {
              setData({
                ...data,
                translation: {
                  ...data.translation,
                  name: value,
                },
              });
            }}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>Thumbnail</Form.Label>
          {data.thumbnail ? (
            <>
              <Button
                variant="light"
                onClick={() => setData({
                  ...data,
                  thumbnail: null,
                })}
              >
                Change
              </Button>
              <img
                alt="Thumbnail"
                className="thumbnail"
                src={thumbnailUrl}
              />
            </>
          ) : (
            <Form.Control
              type="file"
              accept="image/jpeg,image/png"
              onChange={(e) => {
                if (validateFileExtensions(e.target.files[0], ['jpeg', 'png', 'jpg'])) {
                  setData({
                    ...data,
                    thumbnail: e.target.files[0],
                  });
                } else {
                  toastError('Invalid file type');
                  e.target.value = '';
                }
              }}
            />
          )}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={submitting}
          onClick={async () => {
            setSubmitting(true);

            if (!data.name) {
              toastError('Name is required');
              setSubmitting(false);
              return;
            }

            if (!data.thumbnail) {
              toastError('Thumbnail is required');
              setSubmitting(false);
              return;
            }

            let res;

            const postData = {
              ...data,
              name: data.name.trim(),
            };

            delete postData.children;
            delete postData.parent;
            delete postData.createdAt;
            delete postData.updatedAt;
            delete postData.id;

            if (roomId) {
              res = await request(api.editRoom(data.id, postData));
            } else {
              if (parentId) {
                postData.parentId = parentId;
              }

              res = await request(api.addRoom(postData));
            }

            const { success, error } = res;

            if (!success) {
              toastError(error);
              setSubmitting(false);
            } else {
              onClose();
            }
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
