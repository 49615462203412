import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useAppContext } from 'components/AppContext';
import { Toast, useToastContext } from 'components/Toast';
import * as api from 'utils/api';

export default function EditAdmin({
  onClose,
  adminId,
}) {
  const { toastError } = useToastContext();
  const { request } = useAppContext();
  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (adminId) {
      (async () => {
        const { success, result, error } = await request(api.getAdmin(adminId));

        if (success) {
          setData(result);
        } else {
          toastError(error);
        }
      })();
    } else {
      setData({
        level: 1,
        password: '',
        email: '',
        name: '',
      });
    }
  }, [adminId, request, toastError]);

  if (!data) {
    return null;
  }

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      id="modal-edit-admin"
      size="small"
    >
      <Toast
        onModal
      />
      <Modal.Header closeButton>
        <Modal.Title>Edit Admin</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="form-group">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            value={data.email}
            onChange={(e) => setData({
              ...data,
              email: e.target.value,
            })}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>Name</Form.Label>
          <Form.Control
            value={data.name}
            onChange={(e) => setData({
              ...data,
              name: e.target.value,
            })}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>Level</Form.Label>
          <Form.Select
            value={data.level}
            onChange={(e) => setData({
              ...data,
              level: parseInt(e.target.value),
            })}
          >
            <option value={0}>0</option>
            <option value={1}>1</option>
          </Form.Select>
        </Form.Group>
        {data.password !== undefined ? (
          <Form.Group className="form-group">
            <Form.Label>Password</Form.Label>
            <Form.Control
              value={data.password}
              onChange={(e) => setData({
                ...data,
                password: e.target.value,
              })}
            />
          </Form.Group>
        ) : (
          <Button
            variant="light"
            onClick={() => setData({
              ...data,
              password: '',
            })}
          >
            Change Password
          </Button>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={submitting}
          onClick={async () => {
            setSubmitting(true);

            if (!data.name) {
              toastError('Name is required');
              setSubmitting(false);
              return;
            }

            if (!data.email) {
              toastError('Email is required');
              setSubmitting(false);
              return;
            }

            if (data.password === '') {
              toastError('Password is required');
              setSubmitting(false);
              return;
            }

            let res;

            if (adminId) {
              res = await request(api.editAdmin(data.id, data));
            } else {
              res = await request(api.addAdmin(data));
            }

            const { success, error } = res;

            if (!success) {
              toastError(error);
              setSubmitting(false);
            } else {
              onClose();
            }
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
