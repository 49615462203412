import React from 'react';
import { Badge } from 'react-bootstrap';

export default function ImageStatus({ status }) {
  return (
    <>
      {status === 'created' && (
        <Badge bg="warning">Created</Badge>
      )}
      {status === 'ready' && (
        <Badge bg="success">Ready</Badge>
      )}
      {status === 'failed' && (
        <Badge bg="danger">Failed</Badge>
      )}
      {status === 'refunded' && (
        <Badge bg="secondary">Refunded</Badge>
      )}
    </>
  );
}
