import { setJson, getJson, setCookie, removeItem, removeCookie } from './storage';

let data = getJson('authorization');

export const getToken = () => data?.accessToken;

export const isAuth = () => !!(getToken());

export const setAuth = (input) => {
  data = input;
  setJson('authorization', data);
  setCookie('Authorization', `Bearer ${data.accessToken}`);
};

export const logout = () => {
  data = {};
  removeItem('authorization');
  removeCookie('Authorization');
};
