import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useAppContext } from 'components/AppContext';
import { Toast, useToastContext } from 'components/Toast';
import * as api from 'utils/api';

export default function TopUpTenant({
  onClose,
  tenantId,
}) {
  const { toastError } = useToastContext();
  const { request } = useAppContext();
  const [submitting, setSubmitting] = useState(false);
  const [quantity, setQuantity] = useState(null);

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      id="modal-top-up-tenant"
      size="small"
    >
      <Toast
        onModal
      />
      <Modal.Header closeButton>
        <Modal.Title>
          Top up
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="form-group">
          <Form.Label>Quantity</Form.Label>
          <Form.Control
            type="number"
            step={10}
            value={quantity}
            onChange={(e) => setQuantity(parseFloat(e.target.value))}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={submitting}
          onClick={async () => {
            setSubmitting(true);

            if (!quantity) {
              toastError('Quantity is required');
              setSubmitting(false);
              return;
            }

            let res;
            if (tenantId) {
              res = await request(api.topUpTenant(tenantId, { quantity }));
            }

            const { success, error } = res;

            if (!success) {
              toastError(error);
              setSubmitting(false);
            } else {
              onClose();
            }
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
