import Cookies from 'js-cookie';
import { STORAGE_PREFIX } from 'constants/common';

export const getStorageKey = key => (`${STORAGE_PREFIX}.${key}`);

export const getItem = key => localStorage.getItem(getStorageKey(key));

export const setItem = (key, value) => localStorage.setItem(getStorageKey(key), value);

export const removeItem = key => localStorage.removeItem(getStorageKey(key));

export const getJson = (key) => {
  const value = getItem(key);
  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
};

export const setJson = (key, value) => setItem(key, JSON.stringify(value));

export const setCookie = (key, value) => {
  Cookies.set(getStorageKey(key), value, { expires: 365, path: '/', secure: true });
};

export const getCookie = key => Cookies.get(getStorageKey(key));

export const removeCookie = (key) => {
  Cookies.remove(getStorageKey(key), { path: '/' });
};
