import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useQueryParam, NumberParam } from 'use-query-params';
import { Table, Button } from 'react-bootstrap';
import { useToastContext, Toast } from 'components/Toast';
import * as api from 'utils/api';
import Sidebar from 'components/Common/Sidebar';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import { useAppContext } from 'components/AppContext';
import { getLocaleDateString } from 'utils/time';
import CustomPagination from 'components/Common/CustomPagination';
import { ITEMS_PER_PAGE, TenantType, TenantStatus } from 'constants/common';

export default function Tenants() {
  const { request } = useAppContext();
  const { toastError } = useToastContext();
  const { showModal } = useModalContext();
  const [tenants, setTenants] = useState([]);
  const [tenantId = null, setTenantId] = useQueryParam('tenantId', NumberParam);
  const [page = 1, setPage] = useQueryParam('page', NumberParam);
  const [numPages, setNumPages] = useState(0);
  const [editType, setEditType] = useState('edit');

  const fetchTenants = useCallback(async () => {
    const { error, result, success } = await request(api.getTenants({ offset: ((page - 1) * ITEMS_PER_PAGE) }));

    if (success) {
      setTenants(result.tenants);
      setNumPages(Math.ceil(result.count / ITEMS_PER_PAGE));
    } else {
      toastError(error);
    }
  }, [toastError, request, page]);

  useEffect(() => {
    fetchTenants();
  }, [fetchTenants]);

  useEffect(() => {
    if (tenantId) {
      showModal(editType === 'edit' ? ModalKey.EDIT_TENANT_INFO : ModalKey.TOP_UP_TENANT, {
        tenantId,
        onClose: () => {
          setEditType(null);
          setTenantId(null);
          fetchTenants();
        },
      });
    } else {
      showModal(null);
    }
  }, [tenantId, showModal, setTenantId, setEditType, editType, fetchTenants]);

  return (
    <div className="main tenants">
      <Sidebar />
      <div className="right-part">
        <Toast />
        <div className="title">Tenants</div>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Type</th>
              <th>Available Coins</th>
              <th>Sold Coins</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>
                <div className="actions">
                  <Button
                    onClick={() => showModal(ModalKey.EDIT_TENANT_INFO, {
                      onClose: fetchTenants,
                    })}
                  >
                    Add
                  </Button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {tenants.map((tenant) => (
              <tr key={tenant.id}>
                <td>{tenant.id}</td>
                <td>{tenant.name}</td>
                <td>{tenant.type}</td>
                <td>{tenant.coinsAvailable}</td>
                <td>{tenant.coinsSold}</td>
                <td>{tenant.status}</td>
                <td>
                  {moment
                    .utc(tenant.createdAt)
                    .local()
                    .format(`${getLocaleDateString()} HH:mm`)}
                </td>
                <td>
                  {moment
                    .utc(tenant.updatedAt)
                    .local()
                    .format(`${getLocaleDateString()} HH:mm`)}
                </td>
                <td>
                  <div className="actions">
                    <Button
                      variant="light"
                      onClick={() => {
                        setTenantId(tenant.id);
                        setEditType('edit');
                      }}
                    >
                      Edit
                    </Button>
                    {tenant.type !== TenantType.ROOT && (
                      <>
                        <Button
                          variant="light"
                          onClick={() => {
                            setTenantId(tenant.id);
                            setEditType('topup');
                          }}
                        >
                          Top Up
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => showModal(ModalKey.EDIT_TENANT_STATUS, {
                            onConfirm: async () => {
                              const { success, error } = await request(
                                api.editStatusTenant(
                                  tenant.id,
                                  {
                                    status: tenant.status === TenantStatus.ACTIVATED
                                      ? TenantStatus.CLOSED
                                      : TenantStatus.ACTIVATED,
                                  },
                                ),
                              );
                              if (!success) {
                                toastError(error);
                              } else {
                                fetchTenants();
                              }
                            },
                            statusText: tenant.status === TenantStatus.ACTIVATED ? 'deactivate' : 'activate',
                          })}
                        >
                          {tenant.status === TenantStatus.ACTIVATED
                            ? 'Deactivate'
                            : 'Activate'}
                        </Button>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="pagination-wrapper">
          <CustomPagination page={page} setPage={setPage} numPages={numPages} />
        </div>
      </div>
    </div>
  );
}
