import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useQueryParam, NumberParam } from 'use-query-params';
import { Table, Button } from 'react-bootstrap';
import { useToastContext, Toast } from 'components/Toast';
import * as api from 'utils/api';
import Sidebar from 'components/Common/Sidebar';
import { useModalContext } from 'components/ModalContext';
import { ModalKey } from 'constants/modal';
import { useAppContext } from 'components/AppContext';
import { getLocaleDateString } from 'utils/time';

export default function Admins() {
  const { request } = useAppContext();
  const { toastError } = useToastContext();
  const { showModal } = useModalContext();
  const [admins, setAdmins] = useState([]);
  const [adminId = null, setAdminId] = useQueryParam('adminId', NumberParam);

  const fetchAdmins = useCallback(async () => {
    const { error, result, success } = await request(api.getAdmins());

    if (success) {
      setAdmins(result);
    } else {
      toastError(error);
    }
  }, [toastError, request]);

  useEffect(() => {
    fetchAdmins();
  }, [fetchAdmins]);

  useEffect(() => {
    if (adminId) {
      showModal(ModalKey.EDIT_ADMIN, {
        adminId,
        onClose: () => setAdminId(null),
      });
    } else {
      showModal(null);
    }
  }, [adminId, showModal, setAdminId]);

  return (
    <div className="main admins">
      <Sidebar />
      <div className="right-part">
        <Toast />
        <div className="title">
          Admins
        </div>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Email</th>
              <th>Name</th>
              <th>Level</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>
                <div className="actions">
                  <Button
                    onClick={() => showModal(ModalKey.EDIT_ADMIN, {
                      onClose: fetchAdmins,
                    })}
                  >
                    Add
                  </Button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {admins.map(admin => (
              <tr key={admin.id}>
                <td>{admin.id}</td>
                <td>{admin.email}</td>
                <td>{admin.name}</td>
                <td>{admin.level}</td>
                <td>
                  {moment.utc(admin.createdAt).local().format(`${getLocaleDateString()} HH:mm`)}
                </td>
                <td>
                  {moment.utc(admin.updatedAt).local().format(`${getLocaleDateString()} HH:mm`)}
                </td>
                <td>
                  <div className="actions">
                    <Button
                      variant="light"
                      onClick={() => setAdminId(admin.id)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => showModal(ModalKey.DELETE_CONFIRMATION, {
                        onConfirm: async () => {
                          const { success, error } = await request(api.deleteAdmin(admin.id));
                          if (!success) {
                            toastError(error);
                          } else {
                            fetchAdmins();
                          }
                        },
                      })}
                    >
                      Delete
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

    </div>
  );
}
